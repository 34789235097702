import React, {Component} from "react";
import {BallBeat} from "react-pure-loaders";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from '@material-ui/icons/Inbox';
import Paper from '@material-ui/core/Paper';



const styles = {
	fontFamily: "sans-serif",
	textAlign: "center",
};

const serviceColors = [
	{
		service: 'Trello',
		cardColor: 'rgb(0, 121, 191)',
		titleColor: 'white',
		logoLink: 'https://semaine-production.s3.amazonaws.com/products/product/image/2804/large_Trello_app.jpg',
		formatLogo: 'rounded',
		colorTextStatus: 'white',
		screenService: 'Account',
	},
];

export class Admin extends Component {

	state = {
		announcement: false,
		data: [],
	};

	validURL = (str) => {
		const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return !!pattern.test(str);
	};

	updateListRoutes = (status) => {
		let self = this;

		if (status === true)
			this.setState({ data: [] });

		/*axios.get("http://0.0.0.0:5000/getAllroutes").then(response => {
			let out = response.data;
			if (out !== null) {
				Object.keys(out).forEach(function (key) {
					let newelement = {
						'routeName': out[key].routeName,
						'urlredirection': out[key].urlRedirection
					};
					self.setState({
						data: [...self.state.data, newelement]
					})
				});
			}
		})*/
	}

	componentDidMount() {
		this.updateListRoutes(false);
		this.setState({ loading: false,
		});
	}

	render() {
		console.log("HOW DEAR")
		let compList = [];

		for (let i = 0 ; i < this.state.data.length ; i++) {
			let component =  <ListItem button>
				<ListItemIcon>
					<InboxIcon />
				</ListItemIcon>
				<ListItemText primary={this.state.data[i].routeName}
				              onClick={() => {
					              this.setState({announcement: true});
					              axios.post('http://0.0.0.0:5000/deleteRoute', {
						              'routeName': this.state.data[i].routeName,
					              }).then(response => {
						              if (response.status === 200) {
							              this.setState({announcement: false});

							              this.updateListRoutes(true);
							              alert("Route has been correctly removed.")
						              }
					              }).catch(error => {
						              this.setState({announcement: false});
						              alert("You can't remove this route, an error occured.");
					              });
				              }}
				/>
			</ListItem>;
			compList.push(component);
		}
		return (
			<div style={styles}>
				<BallBeat
					color={'white'}
					loading={this.state.announcement}
				/>
				<div className="container">
					<div>
						<img src={"logo.png"} alt="Logo" />
					</div>
				</div>
				<div className={"flip"}>
					<div>
						<h2 style={{ color : 'white', marginRight: '35%'}}>Root Name</h2>
						<TextField
							id="new-route"
							label="Enter your new root"
							value={this.state.rootNameField}
							onChange={val => this.setState({rootNameField: val.target.value})}
							margin="normal"
							variant={"outlined"}
							style={{marginTop: "-5%", }}
						/>
					</div>
					<div>
						<h2 style={{ color : 'white', marginRight: '20%'}}>Url Redirection</h2>
						<TextField
							id="new-url-redirection"
							label="Please add https://."
							value={this.state.urlRedirectionField}
							onChange={val => this.setState({urlRedirectionField: val.target.value})}
							margin="normal"
							variant={"outlined"}
							style={{marginTop: "-5%", marginBottom: '10%' }}
						/>
					</div>

					<div>
						<Button variant="outlined" color={"primary"}
						        onClick={()=>{
							        if (this.validURL(this.state.urlRedirectionField) === false) {
								        alert("Please add a correct URL.");
								        this.setState({urlRedirectionField: '', rootNameField: ''});
								        return;
							        }
							        if (this.state.rootNameField[0] === '/') {
								        alert("Please do not add the '/' character.");
								        this.setState({urlRedirectionField: '', rootNameField: ''});
								        return;
							        }
							        this.setState({announcement: true});
							        axios.post('http://0.0.0.0:5000/addNewRoutes', {
								        'routeName': this.state.rootNameField,
								        'urlRedirection': this.state.urlRedirectionField,
							        }).then(response => {
								        if (response.status === 200) {
									        this.setState({announcement: false,
										        rootNameField: '', urlRedirectionField: ''});
									        this.updateListRoutes(true);
									        alert("Route has been correctly added.")
								        }
							        }).catch(error => {
								        this.setState({announcement: false})
								        alert("An error is occured, no one route was added..");
							        });
						        }}
						>
							Add new route
						</Button>

					</div>
				</div>
				<div className={"register"}>
					<div>
						<h2 style={{ color : 'white', marginRight: '55%'}}>Email</h2>
						<TextField
							id="new-account"
							label="Enter your email"
							value={this.state.emailField}
							onChange={val => this.setState({emailField: val.target.value})}
							margin="normal"
							variant={"outlined"}
							style={{marginTop: "-5%",}}
						/>
					</div>
					<div>
						<h2 style={{ color : 'white', marginRight: '35%'}}>Password</h2>
						<TextField
							id="password"
							label="enter your password"
							value={this.state.passwordField}
							onChange={val => this.setState({passwordField: val.target.value})}
							margin="normal"
							type={"password"}
							variant={"outlined"}
							style={{marginTop: "-5%", marginBottom: '10%' }}
						/>
					</div>

					<div>
						<Button variant="outlined" color={"primary"}
						        className={"button"}
						        onClick={()=>{
							        this.setState({announcement: true});
							        axios.post('http://0.0.0.0:5000/register', {
								        'login': this.state.emailField,
								        'password': this.state.passwordField,
							        }).then(response => {
								        if (response.status === 200) {
									        this.setState({announcement: false,
									        })
									        alert("Account registered !");
								        }
							        }).catch(error => {
								        this.setState({announcement: false})
								        alert("Account not registered, an error is occured.");
							        });
						        }}
						>
							Add New Account
						</Button>

					</div>
				</div>
				<Button variant="contained" style={{backgroundColor: '#DA9B42'}}
				        onClick={() => {
					        window.location.href = "/admin";
				        }}
				>Log Out</Button>
				<div className={"routes"}>
					<h2 style={{ color : 'white'}}>Routes</h2>
					<Paper style={{maxHeight: '90%', overflow: 'auto', backgroundColor: 'transparent'}} elevation={0}>
						<List component="nav" aria-label="main mailbox folders">
							{compList}
						</List>
					</Paper>
				</div>
			</div>
		)
	}
}


export class LoginPage extends Component {

	state = {
		announcement: false,
		connected : false,
	};

	render() {
		let comp = this.state.connected === false ?  <div style={styles}>
			<BallBeat
				color={'white'}
				loading={this.state.announcement}
			/>
			<div className="container">
				<div>
					<img src={"logo.png"} alt="Logo" />
				</div>
			</div>
			<div className={"login"}>
				<div>
					<h2 style={{ color : 'black', marginRight: '55%'}}>Login</h2>
					<TextField
						id="login"
						label="Enter your login e-mail"
						value={this.state.login}
						onChange={val => this.setState({login: val.target.value})}
						margin="normal"
						variant={"outlined"}
						style={{marginTop: "-5%", }}
					/>
				</div>
				<div>
					<h2 style={{ color : 'black', marginRight: '40%'}}>Password</h2>
					<TextField
						id="password"
						label="Enter your password"
						value={this.state.password}
						onChange={val => this.setState({password: val.target.value})}
						margin="normal"
						variant={"outlined"}
						type="password"
						style={{marginTop: "-5%", marginBottom: '10%', }}
					/>
				</div>

				<div>
					<Button variant="outlined" color={"primary"}
					        onClick={()=>{
						        this.setState({announcement: true});
						        axios.post('http://0.0.0.0:5000/login', {
							        'login': this.state.login,
							        'password': this.state.password,
						        }).then(response => {
							        if (response.status === 200) {
								        this.setState({announcement: false,
									        connected: true,});
							        }
						        }).catch(error => {
							        this.setState({announcement: false});
							        alert("Error password or e-mail, please try again!");
						        });
					        }}
					>
						Connexion
					</Button>

				</div>
			</div>

		</div> : <Admin/>;

		return (
			comp
		)
	}
}

