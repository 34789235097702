import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import axios from 'axios';
import { Home } from "./screens/home";
import { LoginPage } from "./screens/admin";
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import ReactGA from 'react-ga'

import firebase from 'firebase/app';
import 'firebase/analytics';


var firebaseConfig = {
    apiKey: "AIzaSyALGeaa2gxsASdmCo4nzDmdnJdOU6OHAcM",
    authDomain: "artist-s-houses-website.firebaseapp.com",
    databaseURL: "https://artist-s-houses-website.firebaseio.com",
    projectId: "artist-s-houses-website",
    storageBucket: "artist-s-houses-website.appspot.com",
    messagingSenderId: "360922482212",
    appId: "1:360922482212:web:ed65c5780083d111cd7ab1",
    measurementId: "G-EVDZE2M17K"
};


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rootNameField: '',
            urlRedirectionField: '',
        };
    }

    /*checkIfRedirectionIsRequested = (currentLocation) => {
        axios.get("http://0.0.0.0:5000/getAllroutes").then(response => {
            let out = response.data;
            if (out !== null) {
                Object.keys(out).forEach((key) => {
                    if ("/" + out[key].routeName === window.location.pathname) {
                        axios.get("http://0.0.0.0:5000/getUrlRedirectionOfRoute?routeName=" + out[key].routeName).then(response => {
                            window.location.href = response.data;
                        })
                    }
                });
            }
        })
    };*/

    componentDidMount() {
        document.title = "Artists Houses"
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();

    }



    render() {
        //console.log(window.location.pathname);
        //this.checkIfRedirectionIsRequested(window.location.pathname);
        return (
            <Router>
                <Switch>
                    <Route exact component={Home} path="/" />
                    <Route exact component={LoginPage} path="/admin" />
                </Switch>
            </Router>
        );
    }
}

export default App;