import React, {Component} from "react";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};

export class Home extends Component {
    state = {
        instaLogo: "instagramLogo.png",
        fbLogo: "facebookLogo.png",
        twitterLogo: "twitterLogo.png"
    };

    render() {
        return (

            <div style={styles}>
                <div className="container">
                    <div>
                        <img src={"logo.png"} alt="Logo" />
                    </div>
                </div>
                <img
                    className={"img"}
                    src={this.state.instaLogo}
                    onMouseEnter={() => {
                        this.setState({
                            instaLogo: "instagramLogoHover.png"
                        })
                    }}

                    onMouseOut={() => {
                        this.setState({
                            instaLogo: "instagramLogo.png"
                        })
                    }}

                    onClick={() => {
                        window.location.href = "https://www.instagram.com/artistshousesfr/"
                    }}

                />
                <img
                    className={"img"}
                    src={this.state.fbLogo}
                    onMouseEnter={() => {
                        this.setState({
                            fbLogo: "facebookLogoHover.png"
                        })
                    }}
                    onMouseOut={() => {
                        this.setState({
                            fbLogo: "facebookLogo.png"
                        })
                    }}
                    onClick={() => {
                        window.location.href = "https://www.facebook.com/artistshousesfr/"
                    }}
                />
                <img
                    className={"img"}
                    src={this.state.twitterLogo}
                    onMouseEnter={() => {
                        this.setState({
                            twitterLogo: "twitterLogoHover.png"
                        })
                    }}
                    onMouseOut={() => {
                        this.setState({
                            twitterLogo: "twitterLogo.png"
                        })
                    }}
                    onClick={() => {
                        window.location.href = "https://twitter.com/Artistshousesfr"
                    }}
                />
            </div>
        )
    }
}